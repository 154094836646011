var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-btn", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isBtnApprReq && _vm.isBtnGw,
        expression: "isBtnApprReq && isBtnGw",
      },
    ],
    attrs: {
      isSubmit: _vm.approvalInfo.isApproval,
      url: _vm.approvalInfo.approvalUrl,
      param: _vm.approvalInfo.param,
      mappingType: "PUT",
      label: "그룹웨어 결재요청",
      icon: "check",
      color: "purple",
    },
    on: { beforeAction: _vm.beforeAction, btnCallback: _vm.btnCallback },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }