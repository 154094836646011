var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-stepper",
    {
      ref: "stepper",
      staticClass: "customStep full-height",
      attrs: {
        animated: "",
        flat: "",
        bordered: "",
        "header-nav": "",
        "alternative-labels": "",
        "done-color": "red-6",
        "active-color": "orange-10",
        "inactive-color": "orange-3",
        "keep-alive": true,
      },
      on: { transition: _vm.transition },
      scopedSlots: _vm._u([
        {
          key: "message",
          fn: function () {
            return [
              _vm._l(_vm.stepItems, function (item, idx) {
                return [
                  String(_vm.vValue) === _vm.stepItems[idx].name && item.message
                    ? [
                        _c(
                          "q-layout",
                          {
                            key: idx,
                            ref: "custom-step-message",
                            refInFor: true,
                            staticStyle: { "min-height": "unset !important" },
                          },
                          [
                            _c(
                              "q-page-container",
                              { staticStyle: { "padding-top": "0px" } },
                              [
                                _c(
                                  "q-page",
                                  { staticClass: "custom-step-page" },
                                  [
                                    _c(
                                      "q-page-sticky",
                                      {
                                        staticClass: "custom-step-offset",
                                        attrs: { position: "top-right" },
                                      },
                                      [
                                        _c("q-btn", {
                                          attrs: {
                                            icon: _vm.messageTranIcon,
                                            color: "indigo-4",
                                            size: "2px",
                                          },
                                          on: { click: _vm.tranMessage },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("q-slide-transition", [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.visible,
                                              expression: "visible",
                                            },
                                          ],
                                        },
                                        [
                                          _c(
                                            "q-banner",
                                            {
                                              staticClass:
                                                "bg-grey-2 text-dark q-px-sm stepmsg",
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$comm.getLangLabel(
                                                      item.message
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              }),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.vValue,
        callback: function ($$v) {
          _vm.vValue = $$v
        },
        expression: "vValue",
      },
    },
    _vm._l(_vm.stepItems, function (item, idx) {
      return _c(
        "q-step",
        {
          key: idx,
          style: { height: _vm.height },
          attrs: {
            name: item.name,
            title: _vm.$comm.getLangLabel(item.title),
            icon: item.icon ? item.icon : "content_paste",
            disable: _vm.disabled || item.disabled,
            done: _vm.vValue > idx,
          },
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.stepClick(item)
            },
          },
        },
        [
          _c(
            "keep-alive",
            [
              item.component
                ? _c(item.component, {
                    key: item.key,
                    tag: "component",
                    attrs: {
                      param: item.param || _vm.param,
                      height: _vm.height,
                    },
                    on: {
                      "update:height": function ($event) {
                        _vm.height = $event
                      },
                      emitStep: _vm.emitStep,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }