<template>
  <div class="fix-height">
    <q-field
      ref="customField"
      class="customDate custom-field"
      :class="[beforeText ? 'before-field' : '']"
      :dense="dense"
      stack-label
      filled
      color="orange-custom"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <template v-if="!editing">
          {{valueText || placeholder}}
        </template>
        <template v-else>
          <input class="col-12 custom-editing-input" 
            ref="custom-editing-input"
            type="text"
            v-model="valueText"
            @input="datachange" />
        </template>
      </template>

      <template v-slot:before>
        <template v-if="beforeText">
          <div class="custom-text-before q-pl-xs q-pr-xs bg-blue-7 text-white">
            {{beforeText}}
          </div>
        </template>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable&&isWrite" name="edit" @click="edit" 
          class="cursor-pointer"
          :class="[editing ? 'text-red' : '']"></q-icon>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-field',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    data: {
      type: Object,
    },
    deptValue: {
      type: String,
      default: 'deptCd',
    },
    plantValue: {
      type: String,
      default: 'plantCd',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'user', // user dept_user plant_dept_user detail_user
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    beforeText: {
      type: String,
      default: '',
    },
    plantCd: {
      type: String,
      default: '',
    },
    isWrite: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: 'userName',
    },
    isDeptManager: {
      type: Boolean,
      default: false,
    },
    isSuspect: {
      type: Boolean,
      default: false,
    },
    isFirstValue: {
      type: Boolean,
      default: true,
    },
    targetDeptCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      editing: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      setEvent: false,
      userListTemp: [],
      vendorListTemp: [],
    };
  },
  computed: {
    userList() {
      let users = this.userListTemp && this.userListTemp.length > 0 ? this.userListTemp : 
        this.plantCd ? this.$_.filter(this.$store.state.systemInfo.users, { plantCd: this.plantCd }) : this.$store.state.systemInfo.users;
      let vendorUsers = this.vendorListTemp && this.vendorListTemp.length > 0 ? this.vendorListTemp : 
        this.$store.state.systemInfo.vendorUsers;
      return this.$_.concat(users, vendorUsers)
    }
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
    userList: {
      handler: function () {
        this.setValueText();
      },
      deep: true,
    },
    '$store.state.systemInfo.users': {
      handler: function () {
        this.userListTemp = this.plantCd ? this.$_.filter(this.$store.state.systemInfo.users, { plantCd: this.plantCd }) : this.$store.state.systemInfo.users
      },
      deep: true,
    },
    '$store.state.systemInfo.vendorUsers': {
      handler: function () {
        this.vendorListTemp = this.$store.state.systemInfo.vendorUsers
      },
      deep: true,
    },
    disabled() {
      if (!this.setEvent && !this.disabled) {
        this.setEvent = true;
        this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
      }
    },
    editing() {
      if (this.editing) {
        this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.search);
      } else {
        this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
      }
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    if (this.isFirstValue && this.$store.state.systemInfo.users && this.$store.state.systemInfo.users.length > 0) {
      this.setValueText();
    }
  },
  mounted() {
    if (!this.disabled) {
      this.setEvent = true;
      this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    // getUserList() {
    //   this.$http.url = this.listUrl;
    //   this.$http.type = 'GET';
    //   this.$http.param = {
    //     plantCd: this.plantCd
    //   }
    //   this.$http.request((_result) => {
    //     this.userList = _result.data;
    //     this.setValueText();
    //   },);
    // },
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        // if (!this.userList) {
        //   this.getUserList();
        // } else 
        if (this.userList.length === 0) {
          // this.$emit('input', null);
          this.valueText = '';
        } else {
          let user = this.$_.find(this.userList, (item) => {
            return item.userId === this.vValue || item.vendorUserCd === this.vValue
          });
          if (user) {
            let userName = user.userName || user.personName
            let deptName = user.deptName || user.vendorName
            if (this.type === 'user') {
              this.valueText = userName + ' ' + user.jobName
            } else if (this.type === 'dept_user') {
              this.valueText = deptName + ' / ' + userName + ' ' + user.jobName;
            } else if (this.type === 'plant_dept_user') {
              // 협력업체 포탈은 사용하지 않음
              this.valueText = user.plantName + ' / ' + deptName + ' / ' + userName;
            } else if (this.type === 'detail_user') {
              this.valueText = user.upDeptName + ' / ' + deptName + ' / ' +  user.empNo + ' / ' + userName + ' / ' + user.birthDate;
            }
          } else {
            if (this.type === 'dept_user' && (this.disabled||!this.editable)) {
              this.valueText = this.vValue;
            } else {
              this.$emit('input', null);
              this.valueText = '';
            }
          }
        }
      } else {
        if (this.data && this.userName) {
          this.valueText = this.data[this.userName] || '';
        } else {
          this.valueText = '';
        }
      }
    },
    edit() {
      /**
       * key-in
       */
      if (!this.editing) {
        this.editing = true;
        setTimeout(() => {
          this.$refs['custom-editing-input'].focus();
        }, 50);
      } else {
        this.editing = false;
      }
      this.$emit('input', null);
      this.valueText = '';
    },
    search() {
      this.popupOptions.title = 'LBL0000560'; // 사용자 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.plantCd,
        isDeptManager: this.isDeptManager,
        isSuspect: this.isSuspect,
        targetDeptCd: this.targetDeptCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let userId = (data[0].userId || data[0].vendorUserCd)
        let userName = (data[0].userName || data[0].personName)
        let deptCd = (data[0].deptCd || data[0].vendorCd)
        let deptName = (data[0].deptName || data[0].vendorName)
        if (this.type === 'user') {
          this.valueText = userName + ' ' + data[0].jobName;
        } else if (this.type === 'dept_user') {
          this.data[this.deptValue] = deptCd
          this.valueText = deptName + ' / ' + userName + ' ' + data[0].jobName;
        } else if (this.type === 'plant_dept_user') {
          // 협력업체 포탈에서 사용하지 않음
          this.data[this.deptValue] = deptCd
          this.data[this.plantValue] = data[0].plantCd
          this.valueText = data[0].plantNm + ' / ' + deptName + ' / ' + userName;
        } else if (this.type === 'detail_user') {
          this.data[this.deptValue] = deptCd
          this.valueText = data[0].upDeptName + ' / ' + deptName + ' / ' + data[0].empNo + ' / ' + userName + ' / ' + data[0].birthDate;
        }
        this.$emit('username', userName);
        this.$emit('input', userId);
        this.$emit('dataChange', userId, data[0]);
        this.$emit('userInfo', data[0]);
      }
    },
    reset() {
      this.valueText = '';
      if (this.type === 'dept_user') {
        this.data[this.deptValue] = '';
      } else if (this.type === 'plant_dept_user') {
        this.data[this.deptValue] = '';
        this.data[this.plantValue] = '';
      } else if (this.type === 'detail_user') {
        this.data[this.deptValue] = '';
      }
      this.$emit('input', null);
      this.$emit('username', null);
      this.$emit('dataChange', null);
      this.$emit('userInfo', null);
    },
    datachange() {
      this.$set(this.data, this.userName, this.valueText)
      this.$emit('dataChange', this.valueText);
    },
  },
};
</script>
<style lang="sass">
.customDate
  .q-field__before
    padding-top: 5px
.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important

.custom-field
  .q-field__native
    cursor: pointer
  </style>