<!--
  그룹웨어 연동 결재버튼
-->
<template>
  <c-btn
    v-show="isBtnApprReq && isBtnGw"
    :isSubmit="approvalInfo.isApproval"
    :url="approvalInfo.approvalUrl"
    :param="approvalInfo.param"
    mappingType="PUT"
    label="그룹웨어 결재요청"
    icon="check"
    color="purple"
    @beforeAction="beforeAction"
    @btnCallback="btnCallback" />
</template>

<script>
// import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'c-appr-gw-btn',
  props: {
    name: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    approvalInfo: {
      type: Object,
      default: function() {
        return {
          sysApprovalRequestId: '', // 결재요청번호
          /**
           * 결재상태코드
           * ASC0000001	결재중
           * ASC0000002	반려
           * ASC9999999	결재완료
           */
          approvalStatusCd: 'ASC0000001', 
          apprEditable: false, // 결재버튼 활성화 기준
          param: null, // 결재 param
          approvalUrl: '', // 결재 url
          approvalTypeCd: '', // 결재유형코드
          isApproval: false, // 결재 submit
          approvalRequestName: '', // 결재요청명 (문서 title)
          approvalConnId: '', // 결재연결용 업무일련번호 (문서 key)
          approvalFormCode: '', // 그룹웨어상 양식코드
          approvalFormTitle: '', // 그룹웨어상 양식명
          gwDatakey: '',
          gwStatus: '',
        }
      },
    },
  },
  data() {
    return {
      isExistGw: false,
      datakey: '',
      status: '',
    };
  },
  computed: {
    isBtnApprReq() {
      return this.editable && this.approvalInfo.apprEditable && !this.approvalInfo.sysApprovalRequestId;
    },
    isBtnGw() {
      return this.approvalInfo.gwStatus.trim() == ''
    }
  },
  watch: {
  },
  mounted() {
  },
  /* methods */
  methods: {
    beforeAction() {
      this.$emit('beforeApprAction');
    },
    btnCallback() {
      if (this.approvalInfo.gwDatakey) {
        window.open('http://gc.daedong.co.kr/app/approval/integration/generalasi/' + this.approvalInfo.gwDatakey + '/popup','gwappr','width=1120, height=768, menubar=no, status=no, toolbar=no');
      } else {
        // 그룹웨어 결재정보 저장 및 결재창 오픈
        this.$http.url = transactionConfig.sys.gwappr.appr.insert.url;
        this.$http.type = 'POST';
        this.$http.param = {
          datakey: '',
          formcode: this.approvalInfo.approvalFormCode,
          formtitle: this.approvalInfo.approvalFormTitle,
          subject: this.approvalInfo.approvalRequestName,
          contents: '',
          insertid: this.$store.getters.user.empNo,
          datastatus: '',
          approvalTypeCd: this.approvalInfo.approvalTypeCd,
          approvalConnId: this.approvalInfo.approvalConnId,
          docid: this.approvalInfo.approvalConnId,
        };
        this.$http.request((_result) => {
          this.$emit('requestAfterAction');
          window.open('http://gc.daedong.co.kr/app/approval/integration/generalasi/' + _result.data.datakey + '/popup','gwappr','width=1120, height=768, menubar=no, status=no, toolbar=no');
        },);
      }
    },
  },
};
</script>

