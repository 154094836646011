<!--
목적 : select 기반 select 컴포넌트(Not Autocomplete)
Detail :
  comboConfig.js 정보를 참고하여 컴포넌트 렌더링시 select 데이터 조회 해서 option 추가
 *
examples:
 *
-->
<template>
  <div class="fix-height">
    <template v-if="isSelect">
      <q-select
        :class="[(stype==='tableselect' ? 'customTableSelect' : ((stype==='tableHeaderselect') ? 'customTableHeaderSelect' : 'customSelect')), isNoneSelect ? 'noneSelectBox' : '']"
        filled
        ref="selectbox"
        :options="items"
        :dense="dense"
        :clearable="clearable"
        :label="convertLabel"
        :color="color"
        :rules="[myRule]"
        :disable="disabled || !editable"
        v-model="vValue"
        @input="input">
        <template v-if="label" v-slot:label>
          <div class="row items-center all-pointer-events">
            <b>
              <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
              <font class="formLabelTitle">{{convertLabel}}</font>
              <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
            </b>
          </div>
        </template>
        <template v-slot:selected-item="scope">
          <template v-if="!isChip">
            {{ scope.opt.label }}
          </template>
          <template v-else> 
            <div :class="['text-' + scope.opt.color, 'text-bold']" :style="scope.opt.style">
              {{ scope.opt.label }}
            </div>
          </template>
        </template>
      </q-select>
    </template>
    <template v-else>
      {{valueText}}
    </template>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-select',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    isNoneSelect: {
      type: Boolean,
      default: false,
    },
    stype: {
      type: String,
      default: 'select',
    },
    color: {
      type: String,
      default: 'orange-custom',
    },
    // TODO : true일경우 select open 속성
    autofocus: {
      type: Boolean,
      default: false,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      // 부모로 부터 받아온 라벨 정보
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    // 부모로 부터 select option을 받아올 경우
    comboItems: {
      type: Array,
      default: () => [],
    },
    // comboItems의 여러 항목 중 화면에 text로 보여지는 정보를 가진 attribute 명(option 생성시 사용됨)
    itemText: {
      type: String,
      required: '',
    },
    // comboItems의 여러 항목 중 실제 값을 가져야 하는 attribute 명(option 생성시 사용됨)
    itemValue: {
      type: String,
      required: '',
    },
    disabled: {
      type: Boolean,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'none', // edit search (none, null)
    },
    typetext: {
      type: String,
      default: '', // edit search (none, null)
    },
    codeGroupCd: {
      type: String,
      default: ''
    },
    codeAttrVal1: {
      type: String,
      default: ''
    },
    stepperGrpCd: {
      type: String,
      default: '',
    },
    default: {
      type: [String, Object],
    },
    isChip: {
      type: Boolean,
      default: false,
    },
    rejectItems: {
      type: Array,
      default: () => [],
    },
    isSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      vValue: null,
    };
  },
  computed: {
    valueText() {
      let _text = '';
      if (this.items && this.items.length > 0 && this.vValue && !this.isSelect) {
        let _data = this.$_.find(this.items, { value: this.vValue.value });
        _text = _data ? _data.label : ''
      }
      return _text;
    }
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.setValue();
    },
    // 부모로 부터 값을 비동기로 가져올 경우 처리
    comboItems: {
      handler: function () {
        this.makeSelectOptions(this.comboItems);
      },
      deep: true,
    },
    // TODO : 부모로 부터 값을 받아오는 경우, 상황에 따라 value 속성 값이 먼저 들어오고 comboItems의 값이 늦게 들어올 수 있으므로,
    // 실제 항목인 items가 변경되면 vValue값을 value값으로 재 설정 해줌
    items() {
      this.setValue();
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    if (this.comboItems && this.comboItems.length > 0) {
      this.makeSelectOptions(this.comboItems);
    } else if (this.codeGroupCd) {
      this.getComboItems();
    } else if (this.stepperGrpCd) {
      this.getStepperItems();
    } else {
      this.makeDefaultOptions(this.items);
    }
  },
  mounted() {
    if (this.autofocus) {
      this.autoFosus();
    }
  },
  beforeDestroy() {},
  destroyed() {},
  beforeUpdate() {},
  updated() {},
  /* methods */
  methods: {
    autoFosus() {
      this.$refs["selectbox"].showPopup();
    },
    input(value) {
      // TODO : 부모에게 변경여부 전달
      let emitData = null;
      if (value) {
        emitData = value.value;
      } else {
        emitData = value;
      }
      this.$emit('input', emitData);
      this.$emit('datachange', value);
    },
    getComboItems() {
      this.$comm.getComboItems(this.codeGroupCd).then(_result => {
        /**
         * default를 넘겨주는 경우 해당 값을 처음에 표시
         */
        if (this.vValue === '') {
          this.vValue = null
        }
        if (this.default && !this.vValue) {
          if (this.default === 'first') {
            this.$emit('input', _result[0][this.itemValue]);
          }
        }
        this.makeSelectOptions(_result);
        this.$emit('setCodeData')
      });
    },
    getStepperItems() {
      this.$comm.getStepItems(this.stepperGrpCd).then(_result => {
        /**
         * default를 넘겨주는 경우 해당 값을 처음에 표시
         */
        if (this.vValue === '') {
          this.vValue = null
        }
        if (this.default && !this.vValue) {
          if (this.default === 'first') {
            this.$emit('input', _result[0][this.itemValue]);
          }
        }
        this.makeSelectOptions(_result);
        this.$emit('setCodeData')
      });
    },
    /**
     * 모델 items 에서 itemText와 itemValue prop를 이용해서, bootstrap select에 적합한 option을 생성
     */
    makeSelectOptions(items) {
      var options = [];
      if (!items || items.length === 0) {
        this.makeDefaultOptions(options)
        this.items = options;
      } else {
        let tempComboItems = [];
        // 쓰기권환이 있으며 활성화 상태인 경우
        if (this.editable && !this.disabled) {
          // 사용여부가 Y인 것만 리스트에 표현한다.
          // default : 사용여부 상관없이 전체
          tempComboItems = this.$_.reject(items, { useFlag: 'N' });
        } else {
          tempComboItems = this.$_.reject(items, { useFlag: 'N' });
        }
        // 추가속성으로 조회조건이 추가적으로있을경우 필터링
        if (this.codeAttrVal1) {
          tempComboItems = this.$_.filter(tempComboItems, {attrVal1 : this.codeAttrVal1 });
        }
        // 제외하고 싶은 아이템이 있는 경우
        if (this.rejectItems && this.rejectItems.length > 0) {
          this.$_.forEach(this.rejectItems, rejectItem => {
            tempComboItems = this.$_.reject(tempComboItems, tempItem => {
              return tempItem[this.itemValue] === rejectItem
            })
          })
        }

        this.$_.forEach(tempComboItems, (_item) => {
          let colorRgbCheck = _item.attrVal1 && _item.attrVal1.indexOf('#') > -1;
          options.push({
            label: _item[this.itemText],
            value: _item[this.itemValue],
            color: colorRgbCheck ? '' : _item.attrVal1,
            fontColor: _item.attrVal2,
            style: colorRgbCheck ? `color:${_item.attrVal1}` : ''
          });
        });
        this.makeDefaultOptions(options)
        this.items = options;
      }
    },
    makeDefaultOptions(_options) {
      if (this.type === 'edit') {
        _options.splice(0, 0, {
          label: this.$comm.getLangLabel('LBLSELECT'), // 선택
          value: null,
        })
      } else if (this.type === 'search') {
        _options.splice(0, 0, {
          label: this.$comm.getLangLabel('LBL0000516'), // 전체
          value: null,
        })
      } else if (this.type === 'allEdit') {
        _options.splice(0, 0, {
          label: this.$comm.getLangLabel('LBL0000567'), // 일괄선택
          value: null,
        })
      } else if (this.type === 'custom') {
        _options.splice(0, 0, {
          label: this.$comm.getLangLabel(this.typetext),
          value: null,
        })
      }
    },
    setValue() {
      if (this.items && this.items.length > 0) {
        let data = this.$_.find(this.items, { value: this.value });
        if (data) {
          this.vValue = data;
        }
      }
    },
    myRule(val) {
      if (this.required && !(val && val.value)) {
        return ''
      }
    },
    getName() {
      let selectData = this.$_.find(this.items, { value: this.vValue.value });
      if (selectData) {
        return selectData.label
      } else {
        return '';
      }
    },
  },
};
</script>
<style lang="sass">
.customSelect
  .q-select__dropdown-icon
    margin-right: -6px
  .q-field__label
    margin-left: 5px
  .q-field__native
    height: 100%
    font-size: 0.95em !important
    padding-bottom: 6px !important
  .q-field__append
    padding-top:2px
    .q-field__focusable-action
      font-size: 0.85em !important
.q-menu
  .q-item
    min-height: 35px
    padding: 2px 16px
    .q-item__label
      font-size: 0.9em !important

.customSelect.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
.customSelect.q-field--dense .q-field__control, .customSelect.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important

.main-header-input .customSelect.q-field--dense .q-field__control, .customSelect.q-field--dense .q-field__marginal
  height: 28px !important
  min-height: 28px !important
  
.q-td .q-field--dense.customTableSelect .q-field__inner
  padding: 0px !important

.customTableSelect
  .q-field__marginal
    height: 24px !important
  .q-select__dropdown-icon
    margin-right: -6px
  .q-field__label
    margin-left: 5px
  .q-field__native
    height: 100%
    font-size: 0.9em !important
    padding-bottom: 6px !important
    span
      margin-bottom: 0px !important
  .q-field__append
    padding-left: 0px !important
    padding-top:2px
    .q-field__focusable-action
      font-size: 0.85em !important
.customTableSelect.q-field--dense .q-field__control, .customSelect.q-field--dense .q-field__marginal
  height: 100% !important
  min-height: 24px !important
.customTableSelect.q-field--filled .q-field__control
  padding: 0px 0px 0px 0px

.q-td .q-field--dense.customTableHeaderSelect .q-field__inner
  padding: 0px !important

.customTableHeaderSelect
  .q-field__marginal
    height: 24px !important
  .q-select__dropdown-icon
    margin-right: -6px
  .q-field__label
    margin-left: 5px
  .q-field__inner
    padding: 0px !important
    border: 1px solid #dddddd
    background: #fff
  .q-field__native
    height: 100%
    font-size: 0.9em !important
    padding-bottom: 6px !important
    span
      margin-bottom: 0px !important
  .q-field__append
    padding-left: 0px !important
    padding-top:2px
    .q-field__focusable-action
      font-size: 0.85em !important
.customTableHeaderSelect.q-field--dense .q-field__control, .customSelect.q-field--dense .q-field__marginal
  height: 24px !important
  min-height: 24px !important
.customTableHeaderSelect.q-field--filled .q-field__control
  padding: 0px 0px 0px 0px
.customTableHeaderSelect.q-field--with-bottom
  padding-top: 5px !important
  padding-bottom: 0px !important
</style>