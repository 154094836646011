var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-dots" },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            "toggle-class": "p-0 mr-2",
            "menu-class": "dropdown-menu-xl dropdown-menu-header-dot",
            variant: "link",
            "no-caret": "",
            right: "",
          },
        },
        [
          _c(
            "span",
            { attrs: { slot: "button-content" }, slot: "button-content" },
            [
              _c(
                "q-btn",
                {
                  staticClass: "topnoti",
                  attrs: {
                    flat: "",
                    dense: "",
                    round: "",
                    color: "white",
                    icon: "notifications",
                  },
                },
                [
                  _c("q-badge", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isBadge,
                        expression: "isBadge",
                      },
                    ],
                    class: [_vm.isBadge, "animatedBadge"],
                    attrs: { rounded: "", floating: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "dropdown-menu-header mb-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "dropdown-menu-header-inner dropdown-menu-header-inner2 bg-mini-dialog",
              },
              [
                _c("div", {
                  staticClass: "menu-header-image opacity-1 dd-header-bg-2",
                }),
                _c("div", { staticClass: "menu-header-content text-white" }, [
                  _c("h5", { staticClass: "menu-header-title" }, [
                    _vm._v(_vm._s(_vm.$label("LBL0000850"))),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "nav-justified" },
            [
              _c(
                "b-tabs",
                {
                  staticClass: "card-header-tab-animation",
                  attrs: { card: "" },
                },
                [
                  _c(
                    "b-tab",
                    { staticClass: "p-3", attrs: { active: "" } },
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                "padding-right": "3px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$label("LBL0000858")))]
                          ),
                          _c("q-badge", {
                            attrs: {
                              rounded: "",
                              color: "success",
                              label: _vm.apprCntTotal,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "scroll-gradient" }, [
                        _c(
                          "div",
                          { staticClass: "scroll-area-sm" },
                          [
                            _c(
                              "VuePerfectScrollbar",
                              { staticClass: "scrollbar-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vertical-timeline-item vertical-timeline-element",
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "vertical-timeline-element-icon bounce-in",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "badge badge-dot badge-dot-xl badge-success",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vertical-timeline-element-content bounce-in",
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "timeline-title timeline-title2",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goPage(
                                                        "/sys/appr/doc/apprTodo"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$label("LBL0000871")
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goPage(
                                                        "/sys/appr/doc/apprTodo"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$label("LBL0000778")
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("q-badge", {
                                                    attrs: {
                                                      rounded: "",
                                                      color: "success",
                                                      label: _vm.apprCnt1,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goPage(
                                                        "/sys/appr/doc/apprTodo"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$label("LBL0000781")
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("q-badge", {
                                                    attrs: {
                                                      rounded: "",
                                                      color: "success",
                                                      label: _vm.apprCnt2,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goPage(
                                                        "/sys/appr/doc/apprTodo"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$label("LBLCONFIRM")
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("q-badge", {
                                                    attrs: {
                                                      rounded: "",
                                                      color: "success",
                                                      label: _vm.apprCnt3,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("span", {
                                                staticClass:
                                                  "vertical-timeline-element-date",
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vertical-timeline-item vertical-timeline-element",
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "vertical-timeline-element-icon bounce-in",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "badge badge-dot badge-dot-xl badge-warning",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vertical-timeline-element-content bounce-in",
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "timeline-title timeline-title2",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goPage(
                                                        "/sys/appr/doc/apprPass"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$label("LBL0000870")
                                                    ) + " "
                                                  ),
                                                  _c("q-badge", {
                                                    attrs: {
                                                      rounded: "",
                                                      color: "warning",
                                                      label: _vm.apprCnt4,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("span", {
                                                staticClass:
                                                  "vertical-timeline-element-date",
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "b-tab",
                    { staticClass: "p-3" },
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _vm._v(" " + _vm._s(_vm.$label("LBL0000859")) + " "),
                          _c("q-badge", {
                            attrs: {
                              rounded: "",
                              color: "success",
                              label: _vm.taskCntTotal,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "scroll-gradient" }, [
                        _c(
                          "div",
                          { staticClass: "scroll-area-lg" },
                          [
                            _c(
                              "VuePerfectScrollbar",
                              { staticClass: "scrollbar-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column",
                                  },
                                  [
                                    _vm.isTask
                                      ? [
                                          _vm._l(
                                            _vm.tasks,
                                            function (task, idx) {
                                              return [
                                                task.cnt > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: idx,
                                                        staticClass:
                                                          "vertical-timeline-item vertical-timeline-element",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "vertical-timeline-element-icon bounce-in",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "badge badge-dot badge-dot-xl badge-primary",
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "vertical-timeline-element-content bounce-in",
                                                            },
                                                            [
                                                              _c(
                                                                "h4",
                                                                {
                                                                  staticClass:
                                                                    "timeline-title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      task.title
                                                                    ) + " "
                                                                  ),
                                                                  _c(
                                                                    "q-badge",
                                                                    {
                                                                      attrs: {
                                                                        rounded:
                                                                          "",
                                                                        color:
                                                                          "success",
                                                                        label:
                                                                          task.cnt,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("p", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      task.subTitle
                                                                    ),
                                                                },
                                                              }),
                                                              _c("span", {
                                                                staticClass:
                                                                  "vertical-timeline-element-date",
                                                              }),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vertical-timeline-item vertical-timeline-element",
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "vertical-timeline-element-icon bounce-in",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "badge badge-dot badge-dot-xl badge-danger",
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vertical-timeline-element-content bounce-in",
                                                  },
                                                  [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "timeline-title",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$message(
                                                              "MSG0000355"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", {
                                                      staticClass:
                                                        "vertical-timeline-element-date",
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }